@use '@angular/material' as mat;
@import 'app-variables';

// Material Design Theme
@include mat.core();
@include mat.core-theme($app-color-theme);
@include mat.grid-list-theme($app-color-theme);
// @include angular-material-theme($app-color-theme);

html {
  background: url(~assets/images/background-0003.jpg) no-repeat center center
    fixed;
  background-size: cover;
}

:root {
  --app-font-lobster: #{$app-font-lobster-name};
  --app-color-white: #{$app-color-white};

  --app-logo-color: rgba(255, 255, 255, 0.85);
  --app-logo-font-family: #{app-font-lobster};
  --app-logo-font-size: 4rem;
}
