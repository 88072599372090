@use '@angular/material' as mat;
@import 'blueprint/variables';

// Material Theme
$app-color-primary: mat.define-palette(mat.$indigo-palette);
$app-color-accent: mat.define-palette(mat.$yellow-palette, A200, A100, A400);
$app-color-warn: mat.define-palette(mat.$red-palette);
$app-color-theme: mat.define-light-theme(
  $app-color-primary,
  $app-color-accent,
  $app-color-warn
);

$app-font-lobster-name: 'Lobster';
$app-font-lobster-path-regular: '~assets/fonts/lobster/Lobster-Regular.ttf';

$app-color-white: #ffffff;
