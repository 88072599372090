@import 'blueprint/variables';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

html {
  width: 100%;
  height: 100%;

  font-size: 16px;
  font-family: -apple-system, 'Helvetica Neue', 'Roboto', sans-serif;

  text-size-adjust: 100%;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

body {
  position: relative;
  overflow: visible;

  margin: 0;
  padding: 0;

  width: 100%;
  max-width: 100%;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;

  text-rendering: optimizeLegibility;

  -webkit-user-drag: none;

  -ms-content-zooming: none;
  touch-action: manipulation;

  word-wrap: break-word;

  text-size-adjust: none;

  font-size: 1.4rem;
}

// App Typography
// --------------------------------------------------

a {
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  opacity: 0.7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1rem;
  margin-bottom: 0.625rem;

  font-weight: 500;
  line-height: 1.2;
}

[padding] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: -0.3rem;
    }
  }
}

h1 + h2,
h1 + h3,
h2 + h3 {
  margin-top: -0.3rem;
}

h1 {
  margin-top: 1.25rem;

  font-size: 1.625rem;
}

h2 {
  margin-top: 1.125rem;

  font-size: 1.5rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

small {
  font-size: 75%;
}

sub,
sup {
  position: relative;

  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

button:focus {
  outline: none;
}
